import logoDark from "@/images/logo.png";
import slideOneImage1 from "@/images/slider/slider-1.jpg";
import slideOneImage2 from "@/images/slider/slider-2.jpg";
import slideOneImage3 from "@/images/slider/slider-3.jpg";
import slideOneImage4 from "@/images/slider/slider-4.jpg";
import slideOneImage5 from "@/images/slider/slider-5.jpg";
import serviceImage1 from "@/images/services/service-1.jpg";
import serviceImage2 from "@/images/services/service-2.jpg";
import serviceImage3 from "@/images/services/service-3.jpg";
import funfactShape from "@/images/shape/about-shape.png";
import galleryOne1 from "@/images/gallery/gallery-1.jpg";
import galleryOne2 from "@/images/gallery/gallery-2.jpg";
import galleryOne3 from "@/images/gallery/gallery-3.jpg";
import galleryOne4 from "@/images/gallery/gallery-4.jpg";
import galleryOne5 from "@/images/gallery/gallery-5.jpg";
import galleryOne6 from "@/images/gallery/gallery-6.jpg";
import testimonial1 from "@/images/testimonial/testimonial-1.jpg";
import testimonial2 from "@/images/testimonial/testimonial-2.jpg";
import testimonial3 from "@/images/testimonial/testimonial-3.jpg";
import blog1 from "@/images/blog/blog-1.jpg";
import blog2 from "@/images/blog/blog-2.jpg";
import blog3 from "@/images/blog/blog-3.jpg";
import blog4 from "@/images/blog/blog-4.jpg";
import blog5 from "@/images/blog/blog-5.jpg";
import blog6 from "@/images/blog/blog-6.jpg";
import team1 from "@/images/team/team-1.jpg";
import team2 from "@/images/team/team-2.jpg";
import team3 from "@/images/team/team-3.jpg";
import team4 from "@/images/team/team-4.jpg";
import comment1 from "@/images/comment-1.jpg";
import comment2 from "@/images/comment-2.jpg";

export const Logo = {
  dark: logoDark
};

export const NavLinksData = [
  {
    name: "Home",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/"
    //   }
    //   {
    //     name: "Home 02",
    //     url: "/index-2"
    //   }
    // ]
  },
  {
    name: "Sobre",
    url: "/about"
  },
  {
    name: "Serviços",
    url: "/service",
    // subItems: [
    //   {
    //     name: "Services",
    //     url: "/service"
    //   }
    //   {
    //     name: "Service Details",
    //     url: "/service-details"
    //   }
    // ]
  },
  {
    name: "Blog",
    url: "/blog",
    // subItems: [
    //   {
    //     name: "Blog Page",
    //     url: "/blog"
    //   }
    //   {
    //     name: "Blog Details",
    //     url: "/blog-details"
    //   }
    // ]
  },
  {
    name: "Contactos",
    url: "/contact"
  }
];

export const TopbarInfos = [
  {
    icon: "icon-placeholder",
    tagLine: "Morada",
    text: "Rua Do Carmo 185A 2785-271 São Domingos de Rana",
    url: "https://www.google.com/maps/place/Macuti+Ar+Condicionado+e+Ventila%C3%A7%C3%A3o+Lda./@38.7205709,-9.3422656,20z/data=!4m15!1m8!3m7!1s0xd1ecf15ab6aa75d:0xf26033bf5437ff50!2sR.+Carmo+185A,+2785-271+S%C3%A3o+Domingos+de+Rana!3b1!8m2!3d38.7205457!4d-9.3420738!16s%2Fg%2F11whthd33r!3m5!1s0x87d5dbbd853bc307:0x79e6959e7ab27711!8m2!3d38.7205355!4d-9.3421002!16s%2Fg%2F11l2cwg2th?entry=ttu&g_ep=EgoyMDI0MTExMC4wIKXMDSoASAFQAw%3D%3D"
  },
  {
    icon: "icon-phone-call",
    tagLine: "Ligue Já",
    text: "969720722 | 917076636",
    url: "tel:969720722"
  },
  {
    icon: "icon-clock",
    tagLine: "Horário",
    text: "Seg-Sáb: 9am - 6pm",
    url: "#"
  }
];

export const SlideOneData = [
  {
    backgroundImage: slideOneImage1,
    title: "Adquira já o seu sistema de Ar Condicionado connosco. Peça já um orçamento.",
    text: "Escolha o melhor instalador",
    button: "Orçamento grátis",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage2,
    title: "Instalação de Ar Condicionado e Reparação",
    text: "Ar Condicionados fiáveis",
    button: "Orçamento grátis",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage3,
    title: "Especialistas em refrigeração",
    text: "Escolha o melhor instalador",
    button: "Orçamento grátis",
    url: "/contact"
  }
];

export const SlideTwoData = [
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialis",
    button: "GET A FREE QUOTE",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage5,
    title: "Keep your family happy this winter, and warm.",
    button: "GET A FREE QUOTE",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialist",
    button: "GET A FREE QUOTE",
    url: "/contact"
  }
];

export const FeatureOneData = [
  {
    icon: "icon-wallet",
    title: "Visita e Orçamento Gratuito"
  },
  {
    icon: "icon-diploma",
    title: "Empresa Certificada"
  },
  {
    icon: "icon-big_clock",
    title: "Resposta Rápida"
  },
  {
    icon: "icon-handshake",
    title: "Qualidade no material"
  },
  {
    icon: "icon-mechanic",
    title: "Trabalhadores com Experiência"
  }
];

export const AboutOneData = {
  title: "Profissionais em sistemas de climatização",
  tagLine: "Macuti",
  content:
    "Somos uma empresa especializada em sistemas de frio e Ar Condicionados. Temos um vasto leque de serviços, passando por projeto, montagem, reparações e manutenções periódicas. O nosso principal objetivo enquanto empresa certificada é a satisfação do cliente.",
  button: {
    url: "/about",
    label: "SABER MAIS"
  }
};

export const ServiceOneData = {
  title: "Os nossos serviços",
  posts: [
    {
      image: serviceImage1,
      icon: "icon-snow",
      title: "Instalação",
      text: "Montagem ou reparação do seu sistema de climatização e/ou Ar Condicionado priorizando a total satisfação do cliente.",
      button: {
        label: "Saber Mais",
        url: "/service-details"
      }
    },
    {
      image: serviceImage2,
      icon: "icon-fire",
      title: "Manutenção e Reparação AC",
      text: "Apoio total ao cliente na manutenção do seu Ar Condicionado ou sistemas de climatização.",
      button: {
        label: "Saber Mais",
        url: "/service-details"
      }
    },
    // {
    //   image: serviceImage3,
    //   icon: "icon-ac",
    //   title: "Reparação",
    //   text: "Reparação de Ar Condicionado",
    //   button: {
    //     label: "Saber Mais",
    //     url: "/service-details"
    //   }
    // },
    {
      image: serviceImage3,
      icon: "icon-ac",
      title: "Manutenção e Reparação Bombas de calor",
      text: "Instalação, manutenção e reparação de bombas de calor  ",
      button: {
        label: "Saber Mais",
        url: "/service-details"
      }
    }
  ]
};

export const CallToActionOneData = {
  title: "Visita e orçamento gratuito, 6 dias por semana",
  button: {
    url: "/contact",
    label: "RECEBER ORÇAMENTO"
  }
};

export const CallToActionTwoData = {
  left: {
    text: "Serviços de Refrigeração",
    title: "Arrefecer"
  },
  right: {
    text: "Serviços de Ventilação",
    title: "Ventilar"
  }
};

export const FunfactOneData = {
  shapeImage: funfactShape,
  title: "Os nossos números",
  posts: [
    {
      count: 522,
      text: "Instalações"
    },
    {
      count: 325,
      text: "Manutenções"
    },
    {
      count: 1212,
      text: "Reparações"
    }
  ]
};

export const GalleryOneData = [
  {
    title: "Maintenance and Repair",
    image: galleryOne1,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne2,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne3,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne4,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne5,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne6,
    url: "#"
  }
];

export const TestimonialOneData = {
  title: "Testimonials",
  posts: [
    {
      image: testimonial1,
      content:
        "A minha experiência com a empresa de montagem de Ar Condicionado foi excelente. Eles foram extremamente profissionais e competentes em todos os aspectos. O serviço foi realizado de forma eficiente e o resultado final superou as minhas expectativas. Cinco estrelas!",
      name: "Rui",
      designation: "Cliente"
    },
    {
      image: testimonial2,
      content:
        "Pedi para instalar um AC num quarto e foram rápidos e eficientes e deram várias opções de Ar Condicionados para escolher. Recomendo.",
      name: "Pedro",
      designation: "Cliente"
    },
    {
      image: testimonial3,
      content:
        "Impossivel pedir melhor, muito prestáveis e com muita atenção ao detalhe.",
      name: "José",
      designation: "Cliente"
    },
    // {
    //   image: testimonial1,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
    //   name: "Della Barnett",
    //   designation: "CEO"
    // },
    // {
    //   image: testimonial2,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
    //   name: "Max Dunn",
    //   designation: "Developer"
    // },
    // {
    //   image: testimonial3,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
    //   name: "Alberta Potter",
    //   designation: "Director"
    // }
  ]
};

export const FooterBottomData = {
  social: [
    {
      icon: "fa fa-instagram",
      url: "https://www.instagram.com/macutiavac/"
    },
    {
      icon: "fa fa-facebook-f",
      url: "https://www.facebook.com/GrupoMacuti"
    }
  ]
};

export const FooterInfo = [
  {
    icon: "icon-placeholder",
    tagLine: "Morada",
    text: "Rua Do Carmo 185A 2785-271 São Domingos de Rana",
    url: "https://www.google.com/maps/place/Macuti+Ar+Condicionado+e+Ventila%C3%A7%C3%A3o+Lda./@38.7205709,-9.3422656,20z/data=!4m15!1m8!3m7!1s0xd1ecf15ab6aa75d:0xf26033bf5437ff50!2sR.+Carmo+185A,+2785-271+S%C3%A3o+Domingos+de+Rana!3b1!8m2!3d38.7205457!4d-9.3420738!16s%2Fg%2F11whthd33r!3m5!1s0x87d5dbbd853bc307:0x79e6959e7ab27711!8m2!3d38.7205355!4d-9.3421002!16s%2Fg%2F11l2cwg2th?entry=ttu&g_ep=EgoyMDI0MTExMC4wIKXMDSoASAFQAw%3D%3D"
  },
  {
    icon: "icon-contact",
    tagLine: "Email",
    text: "geral@macuti.pt",
    url: "mailto:geral@macuti.pt"
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Seg-Sáb: 9am - 6pm",
    url: "#"
  }
];

export const FooterWidgets = {
  about: {
    title: "Sobre a Macuti",
    text: "Somos uma empresa especializada em sistemas de frio e Ar Condicionados.",
    tagLine: "Agendar visita",
    phone: "969720722 | 917076636"
  },
  linkOne: {
    title: "Quick Links",
    menu: [
      {
        lable: "Services",
        url: "#"
      },
      {
        lable: "Request Appointment",
        url: "#"
      },
      {
        lable: "Site Map",
        url: "#"
      },
      {
        lable: "About Us",
        url: "#"
      }
    ]
  },
  linkTwo: {
    menu: [
      {
        lable: "Careers",
        url: "#"
      },
      {
        lable: "Privacy Policy",
        url: "#"
      },
      {
        lable: "Change Location",
        url: "#"
      },
      {
        lable: "Expert Tips",
        url: "#"
      },
      {
        lable: "Terms & Conditions",
        url: "#"
      }
    ]
  }
};

export const BlogOneData = {
  title: "Notícias Recentes",
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Artigo titulo 1",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Artigo titulo 2",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Artigo titulo 3",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    }
  ]
};

export const BlogTwoData = {
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "How does a ductless heat pump work?",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Simple hack to improve A/C efficiency",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Preparing your home before vacation",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog4,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Heating and cooling safety issues",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog5,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Don’t neglect your outdoor unit",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog6,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Have your system professionally inspected",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    }
  ]
};

export const VideoOneData = {
  title: "Watch how we work",
  id: "8DP4NgupAhI"
};

export const AboutTwoData = {
  caption: " 10 <span>anos de experiência</span> ",
  blockTitle: {
    title:
      "Empresa especializada em sistemas de frio e Ar Condicionados. Temos um vasto leque de serviços, passando por projeto, montagem, reparações e manutenções periódicas.",
    tagline: "Macuti"
  },
  lists: [
    {
      text: "Resposta Rápida"
    },
    {
      text: "Eficiência"
    },
    {
      text: "Empresa Certificada"
    },
    {
      text: "Garantia nos nossos produtos"
    },
    {
      text: "Qualidade no material"
    }
  ],
  offer: {
    tagline: "5% desconto",
    text: "Em todas as instalações até dia x."
  }
};

export const TeamOneData = {
  title: "Equipa",
  posts: [
    {
      image: team1,
      name: "Sara Moreira",
      designation: "Direção Financeira",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team2,
      name: "João Neves",
      designation: "Direção Técnica",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team3,
      name: "Wellington Alves",
      designation: "Direção Técnica",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team4,
      name: "Tiago Neves",
      designation: "Direção Operacional",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    }
  ]
};

export const AboutThreeData = {
  blockTitle: {
    tagline: "Our success story",
    title: "Experience quality air conditioning service in broklyn new york"
  },
  summery:
    "There are many variations of passages of lorem ipsum available, but the majorify have suffered alteration in some form by injected humour, or randmised words which don't look even slightly believable.",
  button: {
    lable: "LEARN MORE",
    url: "/about"
  },
  box: [
    {
      icon: "icon-maintenance",
      title: "We make as cool again"
    },
    {
      icon: "icon-big-ac",
      title: "Keep your business cool"
    }
  ]
};

export const AboutFourData = {
  blockTitle: {
    title: "Mais de <span>1200</span> instalações feitas",
    text1:
      "Tincidunt elit magnis nulla facilisis sagittis is maecenas. Sapien nunced amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci.",
    text2:
      "Lorem ipsum dolors sit amet elit magnis amet ultrices purusrfed aliquet. There are many varia-tions of passages of available but the majarity have suffered."
  }
};

export const BenefitOneData = {
  title: "Vantagens",
  posts: [
    {
      icon: "icon-big_clock",
      title: "Resposta Rápida",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-wallet",
      title: "Visita e Orçamento Gratuito",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-diploma",
      title: "Satisfação Garantida",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-mechanic",
      title: "Trabalhadores com Experiência",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    }
  ]
};

export const ServiceSidebarListData = [
  {
    icon: "icon-snow",
    title: "Cooling Services",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#"
      },
      {
        label: "Air Conditioner Repair",
        url: "#"
      },
      {
        label: "Air Conditioner Replacement",
        url: "#"
      },
      {
        label: "Ductless Split AC Systems",
        url: "#"
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#"
      }
    ]
  },
  {
    icon: "icon-fire",
    title: "Heating Services",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#"
      },
      {
        label: "Air Conditioner Repair",
        url: "#"
      },
      {
        label: "Air Conditioner Replacement",
        url: "#"
      },
      {
        label: "Ductless Split AC Systems",
        url: "#"
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#"
      }
    ]
  }
];

export const ServiceSidebarOtherData = [
  {
    icon: "icon-ac",
    title: "Other Services",
    content: {
      icon: "icon-support",
      title: "<span>Contact with us </span>666 888 000"
    }
  },
  {
    icon: "icon-building",
    title: "Commercial Services",
    content: {
      icon: "icon-building",
      title: "<span>Contact with us </span>666 888 000"
    }
  }
];

export const CommentPostData = [
  {
    name: "David Martin",
    image: comment1,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet."
  },
  {
    name: "Jessica Brown",
    image: comment2,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet."
  }
];
